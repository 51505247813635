import {tns} from "tiny-slider/src/tiny-slider.module";

if (document.querySelector('.slide-wrapper')) {

    var slider = tns({
        container: ".slide-wrapper",
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 1
            }
        },
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        loop: false,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: true,
        autoplayButton: false,
        controls: false
    });


    if (document.querySelector('.prev')) {
        document.querySelector('.prev').onclick = function () {
            slider.goTo('prev');
        };
    }

    if (document.querySelector('.next')) {

        document.querySelector('.next').onclick = function () {
            slider.goTo('next');
        };
    }
}

if (document.querySelector('.slide-wrapper-produtos')) {

    var sliderPrdutos = tns({
        container: ".slide-wrapper-produtos",
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 1
            },
            992:{
                items: 3
            },
            1200:{
                items: 4
            }
        },
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        loop: true,
        gutter: 15,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: true,
        autoplayButton: false,
        controls: false
    });


    if (document.querySelector('.prev-produtos')) {
        document.querySelector('.prev-produtos').onclick = function () {
            sliderPrdutos.goTo('prev');
        };
    }

    if (document.querySelector('.next-produtos')) {
        document.querySelector('.next-produtos').onclick = function () {
            sliderPrdutos.goTo('next');
        };
    }
}

if (document.querySelector('.slide-wrapper-categorias')) {

    var sliderCategorias = tns({
        container: ".slide-wrapper-categorias",
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            }
        },
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        loop: true,
        gutter: 15,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: false,
        autoplayButton: false,
        controls: false
    });

    if (document.querySelector('.prev-categorias')) {
        document.querySelector('.prev-categorias').onclick = function () {
            sliderCategorias.goTo('prev');
        };
    }

    if (document.querySelector('.next-categorias')) {
        document.querySelector('.next-categorias').onclick = function () {
            sliderCategorias.goTo('next');
        };
    }
}

if (document.querySelector('.slide-wrapper-fornecedores')) {

    var sliderFornecedores = tns({
        container: ".slide-wrapper-fornecedores",
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 1
            },
            992:{
                items: 3
            },
            1200:{
                items: 5
            }
        },
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        loop: true,
        gutter: 75,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: true,
        autoplayButton: false,
        controls: false
    });

    if (document.querySelector('.prev-fornecedores')) {
        document.querySelector('.prev-fornecedores').onclick = function () {
            sliderFornecedores.goTo('prev');
        };
    }

    if (document.querySelector('.next-fornecedores')) {
        document.querySelector('.next-fornecedores').onclick = function () {
            sliderFornecedores.goTo('next');
        };
    }
}

if (document.querySelector('.slide-wrapper-fotos-sobre')) {

    var sliderFotosSobre = tns({
        container: ".slide-wrapper-fotos-sobre",
        items: 1,
        slideBy: 1,
        autoplay: true,
        autoplayTimeout: 15000,
        loop: true,
        mouseDrag: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: false,
        autoplayButton: false,
        controls: false
    });

    if (document.querySelector('.prev-fotos-sobre')) {
        document.querySelector('.prev-fotos-sobre').onclick = function () {
            sliderFotosSobre.goTo('prev');
        };
    }

    if (document.querySelector('.next-fotos-sobre')) {
        document.querySelector('.next-fotos-sobre').onclick = function () {
            sliderFotosSobre.goTo('next');
        };
    }
}